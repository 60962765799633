import "../index.css";
import moment from "moment/min/moment-with-locales";

export const itemTypes = [
    { id: "w", label: "Music", color: "#396A93" },
    { id: "e", label: "Talk", color: "#99BBD6" },
    { id: "j", label: "Journalism", color: "#FEBE10" },
    { id: "n", label: "News", color: "#519872" },
    { id: "c", label: "Commercial", color: "#D5573B" },
    { id: "b", label: "Break", color: "#99BBD6" },
];

export function get_song_score_color(score, asBackground = false) {
    const prefix = asBackground ? "bg-" : "";
    if (!score || isNaN(score)) return prefix + "no-score";
    if (score < 4) {
        return prefix + "score-worst";
    } else if (score < 5) {
        return prefix + "score-worse";
    } else if (score < 6) {
        return prefix + "score-middle";
    } else if (score < 7) {
        return prefix + "score-better";
    } else if (score >= 7) {
        return prefix + "score-best";
    }
}

export function getSongDeltaColorCss(delta) {
    if (delta < 0) {
        return "score-worst";
    } else if (delta > 0) {
        return "score-best";
    }
}

export function get_category_move_color(category, move) {
    if (category === move) {
        return "gray_color_for_same_category";
    }
}

export function findRadioDayparts(dayparts, radioId, dataType = 1) {
    if (!dayparts || !radioId) return [];
    const result = dayparts.filter(x => x.radio_id === radioId && x.data_type === dataType);
    if (!result.length) return dayparts.filter(x => x.radio_id === -1 && x.data_type === dataType);
    return result;
}

export function formatDaypartText(daypart) {
    if (!daypart) return;
    const startWeekday = moment()
        .day(daypart.weekdays[0] + 1)
        .format("ddd");
    const endWeekday = moment()
        .day(daypart.weekdays[daypart.weekdays.length - 1] + 1)
        .format("ddd");
    const startTime = moment(daypart.start_time, "HH:mm:ss").format("LT");
    const endTime = moment(daypart.end_time, "HH:mm:ss").format("LT");
    return `${startWeekday.charAt(0).toUpperCase() + startWeekday.slice(1)}-${
        endWeekday.charAt(0).toUpperCase() + endWeekday.slice(1)
    } ${startTime} - ${endTime}`;
}

export function convertMomentToDatepickerFormat(momentFormat) {
    const formatTokens = {
        // Year
        YYYY: "yyyy", // Four-digit year
        YY: "yyyy", // Two-digit year

        // Month
        MM: "MM", // Zero-padded month
        M: "MM", // Month

        // Day
        DD: "dd", // Zero-padded day
        D: "dd", // Day

        // Hour
        HH: "HH", // Zero-padded hour (24-hour format)
        H: "H", // Hour (24-hour format)

        // Minute
        mm: "mm", // Zero-padded minute
        m: "m", // Minute

        // Second
        ss: "ss", // Zero-padded second
        s: "s", // Second

        // AM/PM
        A: "a", // AM/PM (upper case)
        a: "aa", // AM/PM (lower case)
    };

    return momentFormat.replace(/Y{2,4}|M{1,2}|D{1,2}|H{1,2}|m{1,2}|s{1,2}|A|a|ddd|dddd/g, match => {
        return formatTokens[match] || match;
    });
}

export function TruncateText({ text, maxLength }) {
    return (
        <span style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}>
            {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
        </span>
    );
}

export const toAutoDecimals = (val, maxDecimals = 2, fallbackValue = "-") => {
    if (!val) return fallbackValue;
    const multiplier = Math.pow(10, maxDecimals);
    return Math.round(val * multiplier) / multiplier;
};

export function getSongScoreColorCss(score, asBackground = false) {
    const prefix = asBackground ? "bg-" : "";
    if (!score || isNaN(score)) return prefix + "no-score";
    if (score < 4) {
        return prefix + "score-worst";
    } else if (score < 5) {
        return prefix + "score-worse";
    } else if (score < 6) {
        return prefix + "score-middle";
    } else if (score < 7) {
        return prefix + "score-better";
    } else if (score >= 7) {
        return prefix + "score-best";
    }
}

export const getRadioNameAsync = async (radioId, radiosPromise) => (await radiosPromise).data.find(x => x.radio_id === radioId)?.radio_name ?? "Unknown";

export const getRadioName = (radioId, resolvedRadios, fallback = "Unknown") => resolvedRadios?.find(x => x.radio_id === radioId)?.radio_name ?? fallback;

export const getDemoName = (demoId, resolvedDemos, fallback = "Unknown") => formatDemographicText(resolvedDemos.find(x => x.demo_id === demoId)) ?? fallback;

export function formatDemographicText(demo) {
    if (!demo) return;
    return `${demo.data_type === 1 ? "Online" : "Cover"}: ${demo.name}`;
}

export function formatTime(time) {
    if (time && !isNaN(time)) {
        const minutes = String(Math.floor(time / 60)).padStart(2, "0");
        const seconds = String(Math.floor(time % 60)).padStart(2, "0");
        return `${minutes}:${seconds}`;
    } else {
        return "00:00";
    }
}

export const mapItemType = id => {
    const itemType = itemTypes.find(item => item.id === id);
    return itemType?.label;
};

export const mapItemTypeColor = id => {
    const itemType = itemTypes.find(item => item.id === id);
    return itemType?.color;
};

export const average = arr => arr?.reduce((acc, val) => acc + val, 0) / arr?.length;

export const mapPlaylistPerspective = (perspectivesWithCategories, categories) => {
    if (!perspectivesWithCategories) return;
    const perspective = perspectivesWithCategories.find(
        x => JSON.stringify(x.categories.map(cg => cg.code).sort()) === JSON.stringify(categories.map(cg => cg.code).sort())
    );
    return perspective;
};

export const mapRotationColor = rotation => {
    const mapping = {
        1: "#983504",
        2: "#D95E0F",
        3: "#FE9829",
        4: "#FFC547",
        5: "#FFE5AD",
    };
    return mapping[rotation];
};

export const getTrendLabel = trend => {
    if (trend === -1) {
        return "down";
    } else if (trend === 0) {
        return "flat";
    } else if (trend === 1) {
        return "up";
    } else {
        return "unknown";
    }
};

export const getScoreTrendLabel = trend => {
    if (trend < -0.5) {
        return "down";
    } else if (trend >= -0.5 && trend <= 0.5) {
        return "flat";
    } else if (trend > 0.5) {
        return "up";
    } else {
        return "unknown";
    }
};

export async function openYoutube(song) {
    const searchString = encodeURIComponent(`${song.artist} - ${song.title}`);
    const url = "https://www.youtube.com/results?search_query=" + searchString;
    window.open(url, "_blank");
}
