import { Card, Col } from "react-bootstrap";
import { Clock, PersonFill } from "react-bootstrap-icons";
import moment from "moment/min/moment-with-locales";

export default function BlogPost({ img, alt, title, datePosted, author }) {
    const formatTitleForURL = title => {
        return title
            .replace(/[^\w\s]/g, "")
            .split(" ")
            .join("-")
            .toLowerCase();
    };

    const dynamicLink = `https://radioanalyzer.com/${formatTitleForURL(title)}`;

    const handleRedirect = () => {
        window.open(dynamicLink, "_blank");
    };
    return (
        <Col>
            <Card className="h-100 w-100">
                <Card.Img className={`w-inherit card-img-top rounded-top-2" : "rounded-2"} object-fit-cover`} src={img} alt={alt} width="100%" height={300} />
                <Card.Body className=" d-flex flex-column align-items-center">
                    <Card.Title className="flex-grow-1">{title}</Card.Title>
                    <Card.Text className="text-body-secondary">
                        <PersonFill /> {author}
                        <br />
                        <Clock /> {moment(datePosted).format("LL")}
                    </Card.Text>
                    <button onClick={handleRedirect} className="btn btn-ra-dark-blue">
                        Read full article
                    </button>
                </Card.Body>
            </Card>
        </Col>
    );
}
