import React, { Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Await, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { FiletypePdf } from "react-bootstrap-icons";
import { ModulePage } from "../../components/pageSelections";
import BlogPost from "../../components/dashboard/BlogPost";
import LatestDataRow from "../../components/dashboard/LatestDataRow";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function Dashboard() {
    const { userInfo, radios } = useRouteLoaderData("root");
    const { latestBlogs } = useLoaderData();
    return (
        <ModulePage>
            <Row className="bg-white p-2 py-3">
                <h2>
                    Welcome{" "}
                    <Suspense>
                        <Await resolve={userInfo}>{resolvedUser => <span className=" fw-bold">{resolvedUser.data.greeting_name}</span>}</Await>
                    </Suspense>
                </h2>
                <Col xs={12}>
                    <h5 className="fw-bold mt-4 mb-4">To get you going</h5>
                    <p>Do you need some help to get started? We got some documents for you!</p>
                    <ul className="ms-3 mt-1 ">
                        <li>
                            RadioAnalyzer Definition{" "}
                            <a href="https://radioanalyzer.com/pdf/Definitions.pdf" target="_blank" rel="noreferrer">
                                <FiletypePdf className="fs-3 text-danger-emphasis pe-auto" />
                            </a>
                        </li>
                        <li>
                            Music Score and Burn{" "}
                            <a href="https://radioanalyzer.com/pdf/Music_Score_and_Burn.pdf" target="_blank" rel="noreferrer">
                                <FiletypePdf className="fs-3 text-danger-emphasis pe-auto" />
                            </a>
                        </li>
                    </ul>
                    <span>
                        If you have any technical questions or you would like some help, please reach us at{" "}
                        <a className="text-dark fw-bold " href="mailto:support@radioanalyzer.com" target="_blank" rel="noreferrer">
                            support@radioanalyzer.com
                        </a>
                    </span>
                </Col>
            </Row>
            <hr className="m-2" />
            <Row className="bg-white p-2 py-3">
                <Suspense
                    fallback={
                        <Col>
                            <h5 className="fw-bold text-center">Your latest data</h5>
                            <LoadingIndicator width={75} />
                        </Col>
                    }>
                    <Await resolve={radios}>
                        {resolvedRadios => (
                            <>
                                {resolvedRadios.data.some(x => x.stream) && (
                                    <Col xs={12} xl={6}>
                                        <h5 className="fw-bold">Your latest Stream data</h5>
                                        <Container fluid>
                                            <Row className="bg-ra-dark-blue text-white fw-bold py-1 rounded-2 fs-8-xs table-header user-select-none h-100">
                                                <Col xs={4} className=" align-content-center">
                                                    Station
                                                </Col>
                                                <Col className=" align-content-center text-center">Music Test</Col>
                                            </Row>
                                        </Container>
                                        <Container fluid className=" mh-500 overflow-y-auto">
                                            {resolvedRadios.data
                                                .filter(x => x.stream)
                                                .map(x => (
                                                    <LatestDataRow key={x.radio_id} radio={x} dataType={1} />
                                                ))}
                                        </Container>
                                    </Col>
                                )}
                            </>
                        )}
                    </Await>
                </Suspense>
            </Row>
            <hr className="m-2" />
            <Row className="bg-white p-2 py-3">
                <h2 className="text-uppercase text-center mb-4">What's New?</h2>
                <Col xl={12}>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3 text-center gy-4">
                        {latestBlogs.map(blog => (
                            <BlogPost
                                key={blog.id}
                                img={`${process.env.REACT_APP_WEBSITE_URL_BACKEND}${blog.Banner.url}`}
                                title={blog.Title}
                                datePosted={blog.createdAt}
                                author={blog.Author}
                            />
                        ))}
                    </Row>
                </Col>
            </Row>
        </ModulePage>
    );
}
