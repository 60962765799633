import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defer } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext(null);
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export let axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}`, "Content-Type": "application/json" },
});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("access_token"));

    const handleLogout = useCallback(() => {
        localStorage.removeItem("access_token");
        setToken(null);
    }, []);

    const handleLogin = useCallback(
        async (formData, origin) => {
            try {
                const response = await axios.post(`${apiUrl}/token`, formData);
                const access_token = response.data["access_token"];
                axiosInstance = axios.create({
                    baseURL: apiUrl,
                    headers: { Authorization: `Bearer ${access_token}`, "Content-Type": "application/json" },
                });

                //validate user access
                try {
                    await axiosInstance.get("/validate_mtt");
                } catch (err) {
                    return "Oops! Looks like you are logging into the wrong tool. Please try at RadioAnalyzer PRO.";
                }

                //set JWT token to local
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("lastActiveTime", Date.now());
                setToken(access_token);

                if (origin) navigate(origin);
            } catch (err) {
                handleLogout();
                return "Woops! Looks like you posted the wrong credentials.";
            }
        },
        [handleLogout, navigate]
    );

    const value = useMemo(
        () => ({
            token,
            onLogin: handleLogin,
            onLogout: handleLogout,
        }),
        [handleLogin, handleLogout, token]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const strapiLoader = async () => {
    const API_KEY = process.env.REACT_APP_STRAPI_API_KEY;
    const IDENTIFIER = process.env.REACT_APP_STRAPI_ADMIN_EMAIL;
    const PASSWORD = process.env.REACT_APP_STRAPI_ADMIN_PASSWORD;
    const BACKEND = process.env.REACT_APP_WEBSITE_URL_BACKEND;
    try {
        const response = await axios.get(`${BACKEND}/api/blogs?populate=*`, {
            auth: {
                username: IDENTIFIER,
                password: PASSWORD,
            },
            headers: {
                Authorization: `Bearer ${API_KEY}`,
                "Content-Type": "application/json",
            },
        });
        const blogs = response.data.data;
        const latestBlogs = blogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 3);
        return defer({ latestBlogs: latestBlogs });
    } catch (error) {
        throw new Error(error.message);
    }
};
