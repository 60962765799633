import { CaretDownFill, CaretRightFill, CaretUpFill } from "react-bootstrap-icons";

export const renderSortIcon = (key, sortConfig) => sortConfig?.key === key && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />);
export function renderTrendIcon(trend) {
    if (trend === 1) {
        return <CaretUpFill height={20} width={20} className="text-success" />;
    } else if (trend === 2) {
        return (
            <>
                <CaretUpFill height={20} width={20} className="text-success" />
                <CaretUpFill height={20} width={20} className="text-success" style={{ marginTop: -10 }} />
            </>
        );
    } else if (trend === -1) {
        return <CaretDownFill height={20} width={20} className="text-danger" />;
    } else if (trend === -2) {
        return (
            <>
                <CaretDownFill height={20} width={20} className="text-danger" />
                <CaretDownFill height={20} width={20} className="text-danger" style={{ marginTop: -10 }} />
            </>
        );
    } else if (trend === 0) {
        return <CaretRightFill height={20} width={20} className="text-secondary" />;
    }
}
