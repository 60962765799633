import React, { Suspense, useState, useMemo, useRef, useCallback, useEffect } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import { Col, Form, ToggleButton, ToggleButtonGroup, Stack } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import PageDesciption from "../../components/PageDesciption";
import PlaylistAutopilotHeader from "../../components/playlistAutopilot/PlaylistAutopilotHeader";
import PlaylistAutopilotRow from "../../components/playlistAutopilot/PlaylistAutopilotRow";
import {
    DataContent,
    InputSelection,
    ModulePage,
    SearchWithDownloadRow,
    SoleDescriptionRow,
    VirtualizedWindowContainer,
} from "../../components/pageSelections";
import { mapPlaylistPerspective } from "../../components/HelperFunctions";
import {
    useSetCategoriesOnPlaylistPerspectiveChange,
    useSetPlaylistPerspectiveOnCategoriesChange,
    useSongCategoriesWithPerspectives,
    getRadioName,
} from "../../components/HelperHooks";
import { useMusicTestTrendModal } from "../../components/TrendOverTime";
import { axiosInstance } from "../../auth/Auth";
import useSortableData from "../../components/useSortableData";
import moment from "moment/min/moment-with-locales";
import RATooltip from "../../components/RATooltip";
import EmptyTableRow from "../../components/EmptyTableRow";

const PlaylistAutopilot = () => {
    const moduleName = "playlistAutopilot";
    const [loading, setLoading] = useState(false);
    const { radios } = useRouteLoaderData("root");
    const [errorCode, setErrorCode] = useState(null);
    const [station, setStation] = useState([]);
    const [defaultStation, setDefaultStation] = useState(
        localStorage.getItem("PlaylistAutopilotStation") ? JSON.parse(localStorage.getItem("PlaylistAutopilotStation")) : null
    );
    const { competitorRadios } = useRouteLoaderData("root");
    const [demos, setDemos] = useState(1);
    const [playlistAutopilotData, setPlaylistAutopilotData] = useState(null);
    const { setTrendSong, MusicTestTrendModalComponent } = useMusicTestTrendModal();

    const [filterData, setFilterData] = useState({
        playlistPerspective: [],
        categories: [],
        showMoves: "changes",
        searchString: "",
    });

    const tableContainerRef = useRef(null);
    const [arePlapStations, setArePlapStations] = useState(false);

    const nullStation = useMemo(() => null, []);
    const perspectivesWithCategories = useSongCategoriesWithPerspectives(nullStation);

    useSetCategoriesOnPlaylistPerspectiveChange(setFilterData, filterData.playlistPerspective, perspectivesWithCategories);
    useSetPlaylistPerspectiveOnCategoriesChange(setFilterData, filterData.categories, perspectivesWithCategories);

    const playlistAutopilotDataMemo = useMemo(() => {
        if (playlistAutopilotData) {
            let filteredData = playlistAutopilotData.data;
            if (filterData.showMoves === "changes") {
                filteredData = filteredData?.filter(item => item.song_category !== item.song_category_move);
            }
            if (filterData.categories.length) {
                filteredData = filteredData.filter(x => filterData.categories.some(c => x.song_category === c.code || x.song_category_move === c.code));
            }
            if (filterData.searchString) {
                filteredData = filteredData.filter(
                    x =>
                        x.song.artist.toLowerCase().includes(filterData.searchString.toLowerCase().trim()) ||
                        x.song.title.toLowerCase().includes(filterData.searchString.toLowerCase().trim()) ||
                        x.song.release_year?.toString().includes(filterData.searchString.trim())
                );
            }
            return filteredData;
        }
    }, [playlistAutopilotData, filterData]);

    useEffect(() => {
        if (perspectivesWithCategories?.length) {
            setFilterData(current => ({
                ...current,
                playlistPerspective: JSON.stringify(perspectivesWithCategories[0].categories.map(x => x.code)),
            }));
        }
    }, [perspectivesWithCategories]);

    const { sortedItems, requestSort, sortConfig } = useSortableData(playlistAutopilotDataMemo, { key: "spins", direction: "descending" });

    function handleStationChange(value) {
        setDefaultStation(null);
        setStation(value);
    }

    async function handleFormSubmit(event) {
        setLoading(true);
        event.preventDefault();
        if (station.length) localStorage.setItem("PlaylistAutopilotStation", JSON.stringify(station[0].radio_id));
        try {
            const radioId = defaultStation ?? station[0].radio_id;
            const response = await axiosInstance.get(`/playlist_autopilot/${radioId}?data_type=1`);
            setErrorCode(null);
            setPlaylistAutopilotData(response.data);
        } catch (error) {
            setPlaylistAutopilotData(null);
            setErrorCode(error?.response?.status ?? 500);
        }
        setLoading(false);
    }

    const virtualizer = useWindowVirtualizer({
        count: sortedItems?.length || 1,
        estimateSize: useCallback(() => 54, []),
        getItemKey: useCallback(i => sortedItems?.length && sortedItems[i].song.item_id, [sortedItems]),
        overscan: 20,
        scrollMargin: tableContainerRef.current?.offsetTop ?? 0,
    });

    const csvHeaders = ["Category Name", "Category Move", "Artist", "Title", "Spins", "Total Spins", "Score Week", "Score Month", "Burn", "Release Year"];
    const csvData = useMemo(
        () =>
            sortedItems?.map(x => [
                x.song_category,
                x.song_category_move,
                x.song.artist,
                x.song.title,
                x.spins,
                x.total_spins,
                x.song_score,
                x.song_score_month,
                x.burn,
                x.song.release_year,
            ]),
        [sortedItems]
    );

    return (
        <>
            <ModulePage>
                <ModulePage.Title className="d-flex">Playlist Autopilot</ModulePage.Title>

                <ModulePage.InputSelection handleFormSubmit={handleFormSubmit} disabled={(!defaultStation && !station.length) || arePlapStations}>
                    <InputSelection.Main>
                        {arePlapStations && ( // ????
                            <p>
                                Contact your RA rep or{" "}
                                <a
                                    className="text-dark fw-bold"
                                    href="mailto:training@radioanalyzer.com?subject=Upgrade%20to%20Playlist%20Autopilot"
                                    target="blank">
                                    Click Here
                                </a>{" "}
                                to upgrade...
                            </p>
                        )}

                        <Suspense fallback={<span>Loading...</span>}>
                            <Await resolve={radios}>
                                {resolvedRadios => {
                                    setArePlapStations(resolvedRadios.data.filter(x => x.stream_settings.playlist_autopilot).length === 0);
                                    return (
                                        <Form.Group controlId="song-search-station" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3 ">
                                            <Form.Label>Station</Form.Label>
                                            <Typeahead
                                                id="song-search-station-selection"
                                                options={resolvedRadios.data.filter(x => x.stream_settings.playlist_autopilot)}
                                                labelKey={"radio_name"}
                                                highlightOnlyResult
                                                placeholder="Select station..."
                                                clearButton={true}
                                                onChange={handleStationChange}
                                                selected={defaultStation ? resolvedRadios.data.filter(x => defaultStation === x.radio_id) : station}
                                                disabled={arePlapStations}
                                            />
                                        </Form.Group>
                                    );
                                }}
                            </Await>
                        </Suspense>
                        <Form.Group as={Col} className="d-flex flex-column mb-3 mb-xl-0 col-12 col-xl-3">
                            <Form.Label>Data type</Form.Label>
                            <ToggleButtonGroup type={"radio"} name="demos" size={"md"} value={demos} className=" ra-switcher">
                                <ToggleButton id={`plap-data-type-stream`} variant={"contained"} disabled={true} value={1} onChange={() => setDemos(1)}>
                                    Stream
                                </ToggleButton>
                                <ToggleButton id={`plap-data-type-ppm`} variant={"contained"} value={2} disabled={true} onChange={() => setDemos(2)}>
                                    Ratings
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Form.Group>
                    </InputSelection.Main>
                </ModulePage.InputSelection>
                <ModulePage.DataContent loading={loading} errorCode={errorCode}>
                    <DataContent.Placeholder show={!playlistAutopilotData}>
                        <PageDesciption
                            title={"Playlist Autopilot"}
                            whatList={[
                                "Playlist meeting prep in seconds, based on your categories, rotations, and guidelines",
                                "Optimal category moves are displayed based on song score – you only need to approve or discard the changes",
                                "Automated export to your music planning system coming soon!",
                            ]}
                            knowList={[
                                { text: "One time setup (we can help with this) is required for optimal results" },
                                { text: "Choose between your playlist, your back pool, or any category(ies) of your choosing" },
                                { text: "Click on trend to see the recent song development of spins and scores" },
                            ]}
                            relatedLinks={[
                                {
                                    title: "Introducing Playlist Autopilot: The Future of Music Curation",
                                    url: "https://radioanalyzer.com/playlist-autopilot/",
                                },
                                {
                                    title: "Playlist Autopilot: We Are Flicking the Switch to 'On'",
                                    url: "https://radioanalyzer.com/playlist-autopilot-update/",
                                },
                            ]}
                            videoSrc="https://www.youtube.com/embed/XNhTC6nnYSw?si=kqyWYKJJJmupIAW1"
                        />
                    </DataContent.Placeholder>
                    {playlistAutopilotData && (
                        <>
                            <SoleDescriptionRow>
                                <Stack direction="horizontal" className="flex-wrap column-gap-2">
                                    <Stack direction="horizontal" gap={1}>
                                        <span className=" text-nowrap">Station:</span>
                                        <Suspense>
                                            <Await resolve={competitorRadios}>
                                                {resolvedRadios => (
                                                    <span className="fw-bold">{getRadioName(playlistAutopilotData?.radio_id, resolvedRadios.data)}</span>
                                                )}
                                            </Await>
                                        </Suspense>
                                    </Stack>
                                    <div className="vr d-none d-xl-inline-block"></div>
                                    <Stack direction="horizontal" gap={1}>
                                        <span>Time period:</span>
                                        <span className="fw-bold">
                                            {moment(playlistAutopilotData.basisWeek).format("L")} -{" "}
                                            {moment(playlistAutopilotData.basisWeek).endOf("isoWeek").format("L")}
                                        </span>
                                    </Stack>
                                    <div className="vr d-none d-xl-inline-block"></div>
                                    <Stack direction="horizontal" gap={1}>
                                        <span>Category:</span>
                                        <span className="fw-bold">
                                            {mapPlaylistPerspective(perspectivesWithCategories, filterData.categories)?.perspective ?? "Custom"}
                                        </span>
                                    </Stack>
                                </Stack>
                            </SoleDescriptionRow>
                            <Stack direction="horizontal" gap={3} className=" mb-2 flex-wrap row-gap-1 ">
                                <Form.Group as={Col} controlId={"playlist-autopilot-perspective"} className="col-12 col-xl-1 min-w-perspective mt-2">
                                    <Form.Label>Perspective</Form.Label>
                                    <Form.Select
                                        value={
                                            perspectivesWithCategories.find(
                                                x => JSON.stringify(x.categories.map(cg => cg.code)) === filterData.playlistPerspective
                                            )
                                                ? filterData.playlistPerspective
                                                : "custom"
                                        }
                                        onChange={e => setFilterData({ ...filterData, playlistPerspective: e.target.value })}>
                                        {perspectivesWithCategories.map(x => (
                                            <option key={x.perspective} value={JSON.stringify(x.categories.map(cg => cg.code))}>
                                                {x.perspective}
                                            </option>
                                        ))}
                                        <option value={"custom"}>Custom</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="col-12 col-xl ">
                                    <Form.Label>Song Categories</Form.Label>
                                    <Typeahead
                                        id="playlistAutopilot-song-categories-selection"
                                        options={perspectivesWithCategories[0]?.categories}
                                        multiple
                                        labelKey={"name"}
                                        clearButton
                                        placeholder="Select song categories..."
                                        selected={filterData.categories}
                                        disabled={arePlapStations}
                                        onChange={value => setFilterData({ ...filterData, categories: value })}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="d-flex flex-column col-12 col-xl-auto">
                                    <Form.Label>
                                        Show Categories
                                        <RATooltip>
                                            Selecting 'Changes' will only display results with song categories that are suggested to be moved. Choosing 'All'
                                            will show categories that should be changed and those that should remain the same.
                                        </RATooltip>
                                    </Form.Label>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="categories"
                                        value={filterData.showMoves}
                                        onChange={value => setFilterData({ ...filterData, showMoves: value })}
                                        className="ra-switcher">
                                        <ToggleButton
                                            id="playlist-autopilot-plap-changes-only"
                                            variant="outline-secondary"
                                            disabled={arePlapStations}
                                            value={"changes"}>
                                            Changes
                                        </ToggleButton>
                                        <ToggleButton
                                            id="playlist-autopilot-plap-changes-all"
                                            variant="outline-secondary"
                                            disabled={arePlapStations}
                                            value={"all"}>
                                            All
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Stack>
                            <Suspense>
                                <Await resolve={competitorRadios}>
                                    {resolvedRadios => (
                                        <SearchWithDownloadRow
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            csvHeaders={csvHeaders}
                                            data={csvData}
                                            filename={`PLAP - ${playlistAutopilotData?.basis_week} - ${getRadioName(
                                                playlistAutopilotData?.radio_id,
                                                resolvedRadios.data
                                            )}`}
                                        />
                                    )}
                                </Await>
                            </Suspense>
                            <div className=" overflow-x-auto">
                                <PlaylistAutopilotHeader sortConfig={sortConfig} requestSort={requestSort} />
                                <VirtualizedWindowContainer className="playlist-autopilot-mw" containerRef={tableContainerRef} virtualizer={virtualizer}>
                                    {virtualizer.getVirtualItems().map(virtualRow => {
                                        const item = sortedItems[virtualRow.index];
                                        if (!item) return <EmptyTableRow key={virtualRow.key} text="No songs" />;
                                        return (
                                            <PlaylistAutopilotRow
                                                key={virtualRow.key}
                                                item={item}
                                                virtualizer={virtualizer}
                                                virtualRow={virtualRow}
                                                setTrendSong={setTrendSong}
                                            />
                                        );
                                    })}
                                </VirtualizedWindowContainer>
                            </div>
                        </>
                    )}
                    <MusicTestTrendModalComponent radioId={playlistAutopilotData?.radio_id} dataType={playlistAutopilotData?.data_type} />
                </ModulePage.DataContent>
            </ModulePage>
        </>
    );
};

export default PlaylistAutopilot;
